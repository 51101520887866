export const baseUrl = "https://allindia.supagrow.in";
export const getHomePage = "api/homepageapi";
export const getAboutPage = "api/aboutpageapi";
export const galleryCategory = "api/getgallerycategory";
export const getGalleryPage = "api/gallery";
export const getLiveStreamPage = "api/livestream";
export const getEventsPage = "api/events";
export const getSponsorPage = "api/sponserpage";
export const postContact = "api/storecontactapi";
export const footer = "api/footer";
