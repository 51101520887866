import { images } from "../../utils/images";
import "./PageLanding.scss";
import { motion } from "framer-motion";

const PageLanding = ({ img, title }) => {
  return (
    <section
      className="page_landing"
      style={{
        background: `url(${img})`,
        backgroundSize: "cover",
      }}
    >
      <div className="content_wrap p_t p_b">
        <div className="title_line">
          <h1>
            {" "}
            {title
              ?.split("")
              .map((char, k) => (
                <motion.span
                  initial={{ translateX: 24, opacity: 0 }}
                  whileInView={{ translateX: 0, opacity: 1 }}
                  transition={{
                    duration: 0.6,
                    ease: "easeInOut",
                    delay: 0.05 * k,
                  }}
                  viewport={{ once: true }}
                  key={k}
                >
                  {char}
                </motion.span>
              ))
              .concat("")}
          </h1>
        </div>
      </div>
    </section>
  );
};

export default PageLanding;
