import "./TeamDetail.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import ExecuteContext from "../../utils/executeContext";
import { IoClose } from "react-icons/io5";
import { baseUrl } from "../../utils/apiData";

const TeamDetail = () => {
  const { teamDetailStatus, setTeamDetailStatus, member } = ExecuteContext();

  return (
    <Offcanvas
      placement="end"
      show={teamDetailStatus}
      onHide={() => setTeamDetailStatus(false)}
      className="team_detail"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>
          <h5>
            {member?.name}
            <br />
            {member?.proffession && (
              <p className="light">{member?.proffession}</p>
            )}
          </h5>

          <button
            type="button"
            className="close"
            onClick={() => setTeamDetailStatus(false)}
          >
            <IoClose />
          </button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: member?.description }}
        ></div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TeamDetail;
