import "./WistiaVideo.scss";
import React, { useEffect, useState } from "react";

const WistiaVideo = ({ vId, styleProp }) => {
  useEffect(() => {
    // Create script elements
    const script1 = document.createElement("script");
    script1.src = `https://fast.wistia.com/embed/medias/${vId}.jsonp`;
    script1.async = true;

    const script2 = document.createElement("script");
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    // Append scripts to the document body
    document.body.appendChild(script1);
    document.body.appendChild(script2);

    // Clean up scripts when the component is unmounted
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return (
    <div className="video_cover">
      <wistia-player
        className="video_player"
        media-id={`${vId}`}
        seo="false"
        style={styleProp}
      ></wistia-player>
    </div>
  );
};

export default WistiaVideo;
