import "./ImageList.scss";
import ImagePreview from "../imagePreview/ImagePreview";
import { Fragment, useState } from "react";
import { LuArrowRight } from "react-icons/lu";
import { baseUrl } from "../../../utils/apiData";
import ExecuteContext from "../../../utils/executeContext";

const ImageList = ({
  imageData,
  videoData,
  categoryList,
  setCategoryId,
  categoryId,
  typeStatus,
  setTypeStatus
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { setSlideNum, setselectedVideo } = ExecuteContext();

  const viewPreview = (num) => {
    setSlideNum(num);
    setModalShow(true);
  };


  return (
    <section className="image_list p_t p_b">
      <div className="content_wrap">
        <h1 className="sec_title">Explore Gallery</h1>
        <div className="switch_tab_wrap">
          <button
            type="button"
            onClick={() => {
              setTypeStatus("image");
              setCategoryId("");
            }}
            className={`a ${typeStatus === "image" ? "active" : ""}`}
          >
            <h5>Explore Images</h5>
            <h6>
              <span>show more</span> <LuArrowRight />
            </h6>
          </button>
          <button
            type="button"
            onClick={() => {
              setTypeStatus("video");
              setCategoryId("");
            }}
            className={`b ${typeStatus === "video" ? "active" : ""}`}
          >
            <h5>Explore Video</h5>
            <h6>
              <span>show more</span> <LuArrowRight />
            </h6>
          </button>
        </div>
        {typeStatus === "video" && (
          <div className="center_title">
            <h4>Documentary of “Navakodi – The Unguarded”</h4>
          </div>
        )}

    
          <div className="categories">
            <button
              type="button"
              className={`main_btn ${categoryId === "" ? "active" : ""}`}
              onClick={() => {
                setCategoryId("");
              }}
            >
              All
            </button>
            {categoryList?.filter(ele => ele?.type === typeStatus)?.map((data) => {
              return (
                <button
                  type="button"
                  className={`main_btn ${
                    categoryId === data?._id ? "active" : ""
                  }`}
                  key={data?._id}
                  onClick={() => {
                    setCategoryId(data?._id);
                  }}
                >
                  {data?.name}
                </button>
              );
            })}
          </div>

        {typeStatus === "image" && (
          <Fragment>
            {imageData?.length > 0 ? (
              <div className="img_grid">
                {imageData
                  ?.filter((ele) => ele.type === `image`)
                  ?.map((data, k) => {
                    return (
                      <div
                        className="img_wrap"
                        onClick={() => viewPreview(k)}
                        key={data?._id}
                      >
                        <img
                          src={`${baseUrl}/${data?.file}`}
                          alt="GAllery Image"
                        />
                      </div>
                    );
                  })}
              </div>
            ) : (
              <p className="text-center">Data not found...</p>
            )}
          </Fragment>
        )}

        {typeStatus === "video" && (
          <Fragment>
            {videoData?.length > 0 ? (
              <div className="img_grid">
                {videoData
                  ?.filter((ele) => ele.type === `video`)
                  ?.map((data, k) => {
                    return (
                      <div
                        className="img_wrap"
                        key={data?._id}
                        onClick={(e) => {
                          setselectedVideo(data);
                          e.stopPropagation();
                          setModalShow(true);
                        }}
                      >
                        <iframe
                          src={data?.file}
                          title="YouTube video player"
                          allowFullScreen
                        ></iframe>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <p className="text-center">Data not found...</p>
            )}
          </Fragment>
        )}
      </div>

      <ImagePreview
        show={modalShow}
        onHide={() => setModalShow(false)}
        type={typeStatus}
      />
    </section>
  );
};

export default ImageList;
