import "./WhatWeDo.scss";
import WistiaVideo from "../../../components/wistiaVideo/WistiaVideo";

const WhatWeDo = ({ data }) => {
  return (
    <section className="what_we_do p_t p_b">
      <div className="content_wrap">
        <h1 className="sec_title">{data?.what_we_do_title}</h1>
        <div className="bi_grid">
          <div className="img_wrap">
            {data?.what_we_do_image && (
              <WistiaVideo vId={data?.what_we_do_image} />
            )}
          </div>
          <div className="para_side">
            <p
              className="dark box"
              dangerouslySetInnerHTML={{
                __html: data?.what_we_do_small_description?.replace(
                  /\n/g,
                  "<br />"
                ),
              }}
            />

            <p
              className="light"
              dangerouslySetInnerHTML={{
                __html: data?.what_we_do_description?.replace(/\n/g, "<br />"),
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
