import {
  Home,
  About,
  Gallery,
  Contact,
  Events,
  LiveStream,
  Sponsors,
  ErrorPage,
} from "../pages/index.js";
import { paths } from "./paths.js";

export const RouteList = [
  {
    path: paths.HOME,
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: paths.ABOUT,
    element: <About />,
  },
  {
    path: paths.GALLERY,
    element: <Gallery />,
  },
  {
    path: paths.CONTACT,
    element: <Contact />,
  },
  {
    path: paths.EVENTS,
    element: <Events />,
  },
  {
    path: paths.SPONSORS,
    element: <Sponsors />,
  },
  {
    path: paths.LIVESTREAM,
    element: <LiveStream />,
  },
];
