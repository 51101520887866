import "./Team.scss";
import { baseUrl } from "../../../utils/apiData";
import ExecuteContext from "../../../utils/executeContext";
import TeamDetail from "../../../components/teamDetail/TeamDetail";
import { Fragment } from "react";

const Team = ({ data, title, padding, lr, img, des, id }) => {
  const { setTeamDetailStatus, setMember } = ExecuteContext();

  return (
    <Fragment>
      <section className={`our_team ${padding}`} id={id}>
        <div className="content_wrap">
          <h1>{title}</h1>

          {lr && (
            <div className="bi_grid p_b">
              <div className="img_wrap">
                <img src={`${baseUrl}/${img}`} alt="Women Empowering" />
              </div>
              <div className="text_side">
                {des && (
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={{ __html: des }}
                  ></div>
                )}
              </div>
            </div>
          )}

          <div className="team_grid">
            {data?.map((profile) => {
              return (
                <div
                  className="team_card"
                  key={profile?._id}
                  onClick={() => {
                    setMember(profile);
                    setTeamDetailStatus(true);
                  }}
                >
                  <div className="img_wrap">
                    <img
                      src={`${baseUrl}/${profile?.image}`}
                      alt="Profile image"
                    />
                  </div>
                  <div className="info">
                    <h5>{profile?.name}</h5>
                    {profile?.proffession && (
                      <p className="dark">{profile?.proffession}</p>
                    )}
                    <button className="underlined">Know more</button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <TeamDetail />
    </Fragment>
  );
};

export default Team;
