import "./Solution.scss";

const Solution = ({ data}) => {
  const solutionList = [
    {
      id: 0,
      title: data?.commitment_heading_1,
      para: data?.commitment_description_1,
    },
    {
      id: 1,
      title: data?.commitment_heading_2,
      para: data?.commitment_description_2,
    },
    {
      id: 2,
      title: data?.commitment_heading_3,
      para: data?.commitment_description_3,
    },
    {
      id: 3,
      title: data?.commitment_heading_4,
      para: data?.commitment_description_4,
    },
    {
      id: 4,
      title: data?.commitment_heading_5,
      para: data?.commitment_description_5,
    },
  ];
  const bg = ["#fce98c", "#fff", "#b8d4e3", "#de411e", "#ffe588"];
  const numColoc = ["#de411e", "#de411e", "#de411e", "#fff", "#de411e"];
  return (
    <section className="solution_sec p_t p_b">
      <div className="content_wrap">
      <h1 className="sec_title">{data?.commitment_title}</h1>
        <div className="solution_list">
       
         

          {solutionList.map((data, k) => {
            return (
              <div
                className="solution_card"
                style={{ background: `${bg[k]}` }}
                key={data?.id}
              >
                <div className="num">
                  <h1 style={{ color: `${numColoc[k]}` }}>0{k + 1}</h1>
                </div>
                <div className="text">
                  <h4>{data?.title}</h4>
                  <p className="light">{data?.para}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Solution;
