import axios from "axios";
import { createContext, useState, useEffect } from "react";
import { baseUrl, footer } from "../utils/apiData";

export const MainContext = createContext();

const WebContext = ({ children }) => {
  const [menuStatus, setMenuStatus] = useState(false);
  const [slideNum, setSlideNum] = useState(0);
  const [imageData, setImagesData] = useState([]);
  const [selectedVideo, setselectedVideo] = useState({});
  const [footerData, setFooterData] = useState({});

  const [founderDetailStatus, setFounderDetailStatus] = useState(false);

  const [teamDetailStatus, setTeamDetailStatus] = useState(false);
  const [member, setMember] = useState({});

  const [eventdetailStatus, setEventDetailStatus] = useState(false);
  const [event, setEvent] = useState({});


  const [sponserStatus, setSponserStatus] = useState(false);
  const [sponser, setSponser] = useState({});

  // footer APi

  const footerApi = async () => {
    try {
      const res = await axios.get(`${baseUrl}/${footer}`);
      if (res?.data?.success) {
        setFooterData(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    footerApi();
  }, []);

  const data = {
    menuStatus,
    setMenuStatus,
    slideNum,
    setSlideNum,
    imageData,
    setImagesData,
    selectedVideo,
    setselectedVideo,
    footerData,
    teamDetailStatus,
    setTeamDetailStatus,
    member,
    setMember,
    founderDetailStatus,
    setFounderDetailStatus,
    eventdetailStatus,
    setEventDetailStatus,
    event,
    setEvent,
    sponserStatus, setSponserStatus,
    sponser, setSponser,
  };
  return <MainContext.Provider value={data}>{children}</MainContext.Provider>;
};

export default WebContext;
