import "./Header.scss";
import { images } from "../../utils/images";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { paths } from "../../utils/paths";
import { RiMenu5Fill } from "react-icons/ri";
import { Fragment } from "react";
import ExecuteContext from "../../utils/executeContext";
import Headroom from "react-headroom";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setMenuStatus } = ExecuteContext();

  return (
    <header>
      <Headroom>
        <section>
          <div className="content_wrap">
            <div className="header_grid">
              <a href="/" className="logo_wrap">
                <img src={images.logo} alt="Brand Logo" />
              </a>

              <nav>
                <NavLink
                  to={paths.HOME}
                  className={
                    location?.pathname === "/" && location?.hash === ""
                      ? "detect"
                      : ""
                  }
                >
                  Home
                </NavLink>
                <NavLink
                  to={paths.ABOUT}
                  className={
                    location?.pathname === "/about_rsc" && location?.hash === ""
                      ? "detect"
                      : ""
                  }
                >
                  About RSC
                </NavLink>
                <NavLink
                  to={paths.ABOUTFOUNDER}
                  className={
                    location?.pathname === "/" &&
                    location?.hash === "#about_founder"
                      ? "detect"
                      : ""
                  }
                >
                  About Navakodi
                </NavLink>
                <NavLink
                  to={paths.ABOUTTEAM}
                  className={
                    location?.pathname === "/about_rsc" &&
                    location?.hash === "#nvakodi's_legacy"
                      ? "detect"
                      : ""
                  }
                >
                  Navakodi's Legacy
                </NavLink>
                <NavLink
                  to={paths.GALLERY}
                  className={location?.pathname === "/gallery" ? "detect" : ""}
                >
                  Gallery
                </NavLink>
                <NavLink
                  to={paths.LIVESTREAM}
                  className={
                    location?.pathname === "/livestream" ? "detect" : ""
                  }
                >
                  Live Stream
                </NavLink>
                <NavLink
                  to={paths.EVENTS}
                  className={location?.pathname === "/events" ? "detect" : ""}
                >
                  Events
                </NavLink>
                <NavLink
                  to={paths.SPONSORS}
                  className={location?.pathname === "/sponsors" ? "detect" : ""}
                >
                  Sponsors
                </NavLink>
              </nav>

              <div className="btn_side">
                <button
                  className="main_btn"
                  onClick={() => navigate(paths.CONTACT)}
                >
                  Get in Touch
                </button>
                <button
                  className="menu_btn"
                  onClick={() => setMenuStatus(true)}
                >
                  <RiMenu5Fill />
                </button>
              </div>
            </div>
          </div>
        </section>
      </Headroom>
    </header>
  );
};

export default Header;
