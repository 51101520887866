import logo from "../assets/images/logo.svg";
import orangeStar from "../assets/images/orangeStar.svg";
import yellowStar from "../assets/images/yellowStar.svg";
import skyStar from "../assets/images/skyStar.svg";
import error from "../assets/images/error.svg";


import contactLanding from "../assets/images/contactLanding.webp";


export const images = {
  logo,
  orangeStar,
  yellowStar,
  skyStar,
  contactLanding,
  error,
};
