import { useContext } from "react";
import { MainContext } from "../components/WebContext";

const ExecuteContext = () => {
  const getContext = useContext(MainContext);

  return getContext;
};

export default ExecuteContext;
