import { PageLayout, Loader } from "../../components/index.js";
import {
  HomeLanding,
  JoinCommunity,
  WhatWeDo,
  OurMission,
  WhyWeCare,
  Solution,
  OurFounder,
} from "./index.js";
import axios from "axios";
import { baseUrl, getHomePage } from "../../utils/apiData.js";
import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import DonationInfo from "../Sponsors/donationInfo/DonationInfo.jsx";
import FounderDetail from "../../components/founderDetail/FounderDetail.jsx";
import { useLocation } from "react-router-dom";

const Home = () => {
  const goTo = (secId) => {
    const section = document.getElementById(`${secId}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const [homePage, setHomePage] = useState({});
  const [sponser, setSponser] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const homePageApi = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${baseUrl}/${getHomePage}`);
      if (res?.data?.success) {
        setHomePage(res?.data?.data?.homepage);
        setSponser(res?.data?.data?.sponser);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    homePageApi();
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (location?.hash) {
        goTo(location?.hash?.slice(1));
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 1000);

    return () => {
      clearTimeout(timeId);
    };
  }, [location?.pathname, location?.hash]);

  return (
    <PageLayout>
      <Helmet>
        <title>Home | All India All Star</title>
        <meta name="description" content="Home | All India All Star" />
      </Helmet>

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <HomeLanding data={homePage} />
          <JoinCommunity data={homePage} />
          <WhatWeDo data={homePage} />
          <Solution data={homePage} />
          <OurMission data={homePage} />
          <WhyWeCare data={homePage} />
          <DonationInfo data={sponser} />

          <OurFounder data={homePage} />
        </Fragment>
      )}
      <FounderDetail data={homePage} />
    </PageLayout>
  );
};

export default Home;
