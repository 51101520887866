import "./FounderDetail.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import ExecuteContext from "../../utils/executeContext";
import { IoClose } from "react-icons/io5";
import Accordion from "react-bootstrap/Accordion";


const FounderDetail = ({ data }) => {
  const { founderDetailStatus, setFounderDetailStatus } = ExecuteContext();

  return (
    <Offcanvas
      placement="end"
      show={founderDetailStatus}
      onHide={() => setFounderDetailStatus(false)}
      className="founder_detail"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>
          <h4>{data?.founder_title}</h4>

          <button
            type="button"
            className="close"
            onClick={() => setFounderDetailStatus(false)}
          >
            <IoClose />
          </button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
       
      <Accordion>
            {data?.founders?.map((data, k) => {
              return (
                <Accordion.Item eventKey={`${k}`} key={data?._id}>
                  <Accordion.Header>
                    <h5>{data?.f_title}</h5>
                  </Accordion.Header>
                  <Accordion.Body>
                  <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: data?.f_description }}
        ></div>
                    
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>


      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default FounderDetail;
