import { NavLink } from "react-router-dom";
import "./DonationInfo.scss";

const DonationInfo = ({ data }) => {
  return (
    <section className="donation_info p_t p_b">
      <div className="content_wrap">
        <h1>{data?.heading}</h1>
        <p className="special">{data?.description}</p>
        <h4 className="text-center">{data?.join_title}</h4>
        {/* <a href={data?.link_donation} target="_blank">
          <h4 className="line text-center">{data?.link_title}</h4>
        </a> */}

<NavLink to="/contact">
          <h4 className="line text-center">{data?.link_title}</h4>
        </NavLink>
      </div>
    </section>
  );
};
export default DonationInfo;
