import "./ErrorText.scss";
import { TbAlertTriangleFilled } from "react-icons/tb";

const ErrorText = (props) => {
  return (
    <div className="error_field">
    <TbAlertTriangleFilled />
      <p className="dark">{props.text ? props.text : props.children}</p>
    </div>
  );
};

export default ErrorText;