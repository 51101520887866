import "./OurMission.scss";
import { images } from "../../../utils/images";
import { baseUrl } from "../../../utils/apiData";

const OurMission = ({data}) => {
  const misisonList = [
    {
      id: 0,
      img:data?.mission_image_1,
      para:data?.mission_title_1,
    },
    {
      id: 1,
      img:data?.mission_image_2,
      para:data?.mission_title_2,
    },
    
  ];
  return (
    <section className="our_mission p_t p_b">
      <div className="content_wrap">
        <h1 className="sec_title">{data?.mission_title}</h1>
        <div className="mission_sec">
          {misisonList.map((data, l) => {
            return (
              <div className="mission_card" key={data?.id}>
                
                <div className="img_wrap">
                  <div className="num"><h1>0{l+1}</h1></div>
                  <img src={`${baseUrl}/${data?.img}`} alt="Mission" />
                </div>
                <h4>{data?.para}</h4>

              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default OurMission;
