import "./OurCharater.scss";
import { images } from "../../../utils/images";
import { LuArrowRight } from "react-icons/lu";
import { Fragment } from "react";
import { baseUrl } from "../../../utils/apiData";
import { paths } from "../../../utils/paths";
import { useNavigate } from "react-router-dom";

const OurCharater = ({ data }) => {
  const navigate = useNavigate();
  const dataList = [
    {
      id: 0,
      img: data?.commitment_1_image,
      title: data?.commitment_1_title,
      para: data?.commitment_1_description,
    },
    {
      id: 1,
      img: data?.commitment_2_image,
      title: data?.commitment_2_title,
      para: data?.commitment_2_description,
    },
    {
      id: 2,
      img: data?.commitment_3_image,
      title: data?.commitment_3_title,
      para: data?.commitment_3_description,
    },
    {
      id: 3,
      img: data?.commitment_4_image,
      title: data?.commitment_4_title,
      para: data?.commitment_4_description,
    },
    {
      id: 4,
      img: data?.commitment_5_image,
      title: data?.commitment_5_title,
      para: data?.commitment_5_description,
    },
  ];
  return (
    <Fragment>
      <div>
        <section className="title_sec p_t">
          <div className="content_wrap">
            <h1 className="sec_title">{data?.chapter_title}</h1>
          </div>
        </section>

        {dataList?.map((item, k) => {
          return (
            <section
              className="commit_sec p_b"
              key={item?.id}
              style={{ top: `${48 + 16 * k}px` }}
            >
              <div className="content_wrap">
                <div className="title_line">
                  <h1>0{k + 1}</h1>
                  <h4>{item?.title}</h4>
                </div>
                <div className="bi_sec">
                  <div className="img_wrap">
                    <img src={`${baseUrl}/${item?.img}`} alt="Commitments" />
                  </div>
                  <div className="text_wrap">
                    <div className="dark" dangerouslySetInnerHTML={{ __html: item?.para }} />
                  </div>
                </div>
              </div>
            </section>
          );
        })}
      </div>
      <section className="cta p_b">
        <div className="content_wrap">
          <div className="box">
            <h3>{data?.cta_title}</h3>
            <div className="btn_side">
              <button
                className="main_btn"
                onClick={() => navigate(paths.CONTACT)}
              >
                {data?.cta_button_name} <LuArrowRight />
              </button>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default OurCharater;
