import { useState } from "react";
import "./EventGrid.scss";
import { LuArrowRight } from "react-icons/lu";
import { baseUrl } from "../../../utils/apiData";
import ExecuteContext from "../../../utils/executeContext";

const EventGrid = ({ events, upcommingEvents }) => {
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleString("default", {
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };
  const [typeStatus, setTypeStatus] = useState("event");
  const { setEventDetailStatus, setEvent } = ExecuteContext();
  return (
    <section className="video_event p_t p_b">
      <div className="content_wrap">
        <div className="switch_tab_wrap">
          <button
            type="button"
            onClick={() => setTypeStatus("event")}
            className={`a ${typeStatus === "event" ? "active" : ""}`}
          >
            <h5>Events</h5>
            <h6>
              <span>show more</span> <LuArrowRight />
            </h6>
          </button>
          <button
            type="button"
            onClick={() => setTypeStatus("upcommingEvent")}
            className={`b ${typeStatus === "upcommingEvent" ? "active" : ""}`}
          >
            <h5>Upcomming Events</h5>
            <h6>
              <span>show more</span> <LuArrowRight />
            </h6>
          </button>
        </div>
        <h1 className="sec_title">
          {typeStatus === "event" ? "Events" : "Upcomming Events"}
        </h1>

        {typeStatus === "event" ? (
          events?.length > 0 ? (
            <div className="event_grid">
              {events?.map((item, k) => {
                return (
                  <div className="event_card" key={k}>
                    <div className="img_wrap">
                      <img src={`${baseUrl}/${item?.image}`} alt="Article" />
                    </div>
                    <div className="info_side">
                      <div className="top">
                        <p className="dark date">{formatDate(item?.publish_date)} </p>
                        <h5>{item?.name}</h5>
                        <p className="dark">{item?.content}</p>
                      </div>

                      <button
                        className="main_btn"
                        onClick={() => {
                          setEvent(item);
                          setEventDetailStatus(true);
                        }}
                      >
                        Know More <LuArrowRight />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="text-center alert_text">
              There are no events at the moment...
            </p>
          )
        ) : upcommingEvents?.length > 0 ? (
          <div className="event_grid">
            {upcommingEvents?.map((item, k) => {
              return (
                <div className="event_card" key={k}>
                  <div className="img_wrap">
                    <img src={`${baseUrl}/${item?.image}`} alt="Article" />
                  </div>
                  <div className="info_side">
                    <div className="top">
                      <p className="dark date">{formatDate(item?.publish_date)}</p>
                      <h5>{item?.name}</h5>
                      <p className="dark">{item?.content}</p>
                    </div>

                    <button
                      className="main_btn"
                      onClick={() => {
                        setEvent(item);
                        setEventDetailStatus(true);
                      }}
                    >
                      Know More <LuArrowRight />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="text-center alert_text">
            There are no events at the moment...
          </p>
        )}
      </div>
    </section>
  );
};

export default EventGrid;
