import "./OurFounder.scss";
import { LuArrowRight } from "react-icons/lu";
import { baseUrl } from "../../../utils/apiData";
import ExecuteContext from "../../../utils/executeContext";

const OurFounder = ({ data }) => {
  const {setFounderDetailStatus} = ExecuteContext();
  return (
    <section className="our_founder p_t p_b" id="about_founder">
      <div className="content_wrap pb-5" >
        <h1 className="sec_title">{data?.founder_title}</h1>
        <div className="bi_grid">
          <div className="img_wrap">
            <img
              src={`${baseUrl}/${data?.image}`}
              alt="Founder"
              className="founder_profile"
            />
          </div>
          <div className="founder_info">
            <h1>{data?.founder_name}</h1>
            {data?.position && <h5>{data?.position}</h5>}
            <div  className="des" dangerouslySetInnerHTML={{ __html:data?.description }} />           
            {data?.founder_description_1 && 
              <div  className="des" dangerouslySetInnerHTML={{ __html:data?.founder_description_1 }} />
            }

            {data?.founder_description_2 && 
               <div className="des"  dangerouslySetInnerHTML={{ __html:data?.founder_description_2 }} />              
            }
            <button className="main_btn" onClick={() => setFounderDetailStatus(true)}>
              Know More <LuArrowRight />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurFounder;
