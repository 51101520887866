import { Loader, PageLanding, PageLayout } from "../../components/index.js";
import OurCharater from "./ourCharater/OurCharater.jsx";
import Team from "./team/Team.jsx";
import WhoWeAre from "./whoWeAre/WhoWeAre.jsx";
import WhyPleadge from "./whyPleadge/WhyPleadge.jsx";
import { baseUrl, getAboutPage } from "../../utils/apiData.js";
import axios from "axios";
import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Gratitude from "./gratitude/Gratitude.jsx";
import { paths } from "../../utils/paths.js";

const About = () => {
  const goTo = (secId) => {
    const section = document.getElementById(`${secId}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [aboutPage, setAboutPage] = useState({});
  const [menTeam, setMenTeam] = useState([]);
  const [womenTeam, setWoMenTeam] = useState([]);
  const [loading, setLoading] = useState(false);
  const [landingImg, setLandingImg] = useState("");

  const aboutPageApi = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${baseUrl}/${getAboutPage}`);
      if (res?.data?.success) {
        setLandingImg(res.data?.data?.landing_image);
        setAboutPage(res?.data?.data?.aboutPage);
        setMenTeam(res?.data?.data?.men_team);
        setWoMenTeam(res?.data?.data?.women_team);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    aboutPageApi();
  }, []);

  const location = useLocation();

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (location?.hash) {
        goTo(location?.hash?.slice(1));
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 1000);

    return () => {
      clearTimeout(timeId);
    };
  }, [location?.pathname, location?.hash]);

  return (
    <PageLayout>
      <Helmet>
        <title>About RSC | All India All Star</title>
        <meta name="description" content="About RSC | All India All Star" />
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <PageLanding title="About RSC" img={`${baseUrl}/${landingImg}`} />

          <WhoWeAre data={aboutPage} />
          <WhyPleadge data={aboutPage} />
          <OurCharater data={aboutPage} />
          <Team
            data={womenTeam}
            title={aboutPage?.women_emp_title}
            padding="p_t"
            img={aboutPage?.image_women}
            des={aboutPage?.women_emp_desc}
            lr={true}
            id="woman"
          />

          <Team
            data={menTeam}
            title={aboutPage?.team_title}
            padding="p_t p_b"
            lr={false}
            id="nvakodi's_legacy"
          />

          <div id="gratitude">
            <Gratitude data={aboutPage} />
          </div>
        </Fragment>
      )}
    </PageLayout>
  );
};

export default About;
