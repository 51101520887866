import { baseUrl } from "../../../utils/apiData";
import ExecuteContext from "../../../utils/executeContext";
import "./SportList.scss";
import { LuArrowRight } from "react-icons/lu";

const SportList = ({ dataList }) => {
  const { setSponserStatus, setSponser } = ExecuteContext();
  return (
    <section className="sponser_list p_t p_b">
      <div className="content_wrap">
        <h1>Our Sponsors</h1>
        {dataList?.length > 0 && (
          <div className="list_grid">
            {dataList?.map((data) => {
              return (
                <div className="sponser_card" key={data?._id}>
                  <div className="img_wraper">
                    <img src={`${baseUrl}/${data?.image}`} alt="Sponser" />
                  </div>
                  <h5>{data?.name}</h5>
                  <p className="dark">{data?.description}</p>
                  <button className="main_btn"
                  onClick={() => {
                    setSponser(data);
                    setSponserStatus(true);
                  }}
                  >
                    Know More <LuArrowRight />
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </section>
  );
};

export default SportList;
