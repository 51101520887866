import { Fragment, useEffect } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import MenuDrower from "../menuDrower/MenuDrower";
import { useLocation } from "react-router-dom";

const PageLayout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/about_navakodi") {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);
  return (
    <Fragment>
      <Header />
      <MenuDrower />
      {children}
      <Footer />
    </Fragment>
  );
};

export default PageLayout;
