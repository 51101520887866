import { baseUrl } from "../../../utils/apiData";
import "./Gratitude.scss";

const Gratitude = ({ data }) => {
  return (
    <section>
      <div className="content_wrap p_t p_b" >
        <div className="bi_grid pb-3">
          <div className="img_wrap">
            <img
              src={`${baseUrl}/${data?.gratitude_image}`}
              alt="Women Empowering"
            />
          </div>
          <div className="text_side">
            <h2>{data?.gratitude_title}</h2>
            {data?.gratitude_description && (
              <div
                className="desc"
                dangerouslySetInnerHTML={{
                  __html: data?.gratitude_description,
                }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gratitude;
