import axios from "axios";
import { Loader, PageLanding, PageLayout } from "../../components/index.js";
import EventGrid from "./eventGrid/EventGrid.jsx";
import { baseUrl, getEventsPage } from "../../utils/apiData.js";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import EventDetail from "../../components/eventDetail/EventDetail.jsx";

const Events = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [upcommingEvents, setUpcommingEvents] = useState([]);
  const [landingImg, setLandingImg] = useState("");

  const eventPageApi = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${baseUrl}/${getEventsPage}`);
      if (res?.data?.success) {
        setEvents(res?.data?.data?.event);
        setUpcommingEvents(res?.data?.data?.upcoming_event);
        setLandingImg(res?.data?.data?.landing_image);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    eventPageApi();
  }, []);
  return (
    <PageLayout>
      <Helmet>
        <title>Events | All India All Star</title>
        <meta name="description" content="Events | All India All Star" />
      </Helmet>

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <PageLanding
            title="Upcomming Events"
            img={`${baseUrl}/${landingImg}`}
          />
          <EventGrid events={events} upcommingEvents={upcommingEvents} />
          <EventDetail />
        </Fragment>
      )}
    </PageLayout>
  );
};

export default Events;
