import "./App.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RouteList } from "./utils/routesList.js";

function App() {
  const router = createBrowserRouter(RouteList);
  return <RouterProvider router={router} />;
}

export default App;
