import { Fragment, useEffect, useState } from "react";
import { Loader, PageLanding, PageLayout } from "../../components/index.js";
import ImageList from "./imageList/ImageList.jsx";
import axios from "axios";
import {
  baseUrl,
  getGalleryPage,
  galleryCategory,
} from "../../utils/apiData.js";
import { Helmet } from "react-helmet";
import ExecuteContext from "../../utils/executeContext.js";

const Gallery = () => {
  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { setImagesData } = ExecuteContext();
  const [imagesList, setImagesList] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [landingImg, setLandingImg] = useState("");
  const [categoryList, setCartegoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");

  // Get Gallery Category

  const getCategoryApi = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${baseUrl}/${galleryCategory}`);
      if (res?.data?.success) {
        setCartegoryList(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategoryApi();
  }, []);

  // get Gallery APi
  const galleryApi = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${baseUrl}/${getGalleryPage}`, {
        category_id: `${categoryId}`,
      });
      if (res?.data?.success) {
        setLandingImg(res.data?.data?.landing_image);
        setImagesList(res?.data?.data?.images);
        setImagesData(res?.data?.data?.images);
        setVideos(res?.data?.data?.videos);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    galleryApi();
  }, []);

  useEffect(() => {
    goToTop();
    galleryApi();
  }, [categoryId]);
  const [typeStatus, setTypeStatus] = useState("image");
  return (
    <PageLayout>
      <Helmet>
        <title>Gallery | All India All Star</title>
        <meta name="description" content="Gallery | All India All Star" />
      </Helmet>

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <PageLanding title="Our Gallery" img={`${baseUrl}/${landingImg}`} />
          <ImageList
            imageData={imagesList}
            videoData={videos}
            categoryList={categoryList}
            setCategoryId={setCategoryId}
            categoryId={categoryId}
            typeStatus={typeStatus}
            setTypeStatus={setTypeStatus}
          />
        </Fragment>
      )}
    </PageLayout>
  );
};

export default Gallery;
