import "./FormSec.scss";
import * as Yup from "yup";
import ErrorText from "../../../components/errorText/ErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LuArrowRight } from "react-icons/lu";
import { useState } from "react";
import axios from "axios";
import { baseUrl, postContact } from "../../../utils/apiData";

const FormSec = () => {
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);

  // Contact Form Submit API
  const contactSubmitApi = async (data) => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${postContact}`, { ...data });
      if (res?.data?.success) {
        setSuccess(true);
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  const initialValues = {
    name: "",
    phone: "",
    email: "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Field is required!"),
    email: Yup.string()
      .email("invalid email formate!")
      .required("Field is required!"),
    phone: Yup.number().required("Field is required!"),
    message: Yup.string().required("Field is required!"),
  });

  const onSubmit = (values) => {
    contactSubmitApi(values);
  };
  return (
    <section className="form_sec p_t p_b">
      <div className="content_wrap">
        <h1 className="sec_title">Get in Touch</h1>

        {success ? (
          <div className="success_card">
            <p className="dark">
              Thank you! Your submission has been received!
            </p>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className="field">
                <label htmlFor="Name">Name</label>
                <Field
                  type="text"
                  placeholder="Your name"
                  id="Name"
                  name="name"
                />
                <ErrorMessage name="name" component={ErrorText} />
              </div>

              <div className="field">
                <label htmlFor="Phone">Phone</label>
                <Field
                  type="number"
                  placeholder="Your phone"
                  id="Phone"
                  name="phone"
                />
                <ErrorMessage name="phone" component={ErrorText} />
              </div>
              <div className="field">
                <label htmlFor="Email">Email</label>
                <Field
                  type="email"
                  placeholder="Your Email"
                  id="Email"
                  name="email"
                />
                <ErrorMessage name="email" component={ErrorText} />
              </div>
              <div className="field">
                <label htmlFor="Message">Message</label>
                <Field
                  as="textarea"
                  type="text"
                  placeholder="Your message"
                  id="Message"
                  name="message"
                />
                <ErrorMessage name="message" component={ErrorText} />
              </div>

              <button type="submit" className="main_btn">
                {loader ? "Loading..." : "Submit"} {!loader && <LuArrowRight />}
              </button>
            </Form>
          </Formik>
        )}
      </div>
    </section>
  );
};

export default FormSec;
