import "./ImagePreview.scss";
import Modal from "react-bootstrap/Modal";
import { Fragment } from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { baseUrl } from "../../../utils/apiData";
import ExecuteContext from "../../../utils/executeContext";

const ImagePreview = (props) => {
  const { slideNum, setSlideNum, imageData, selectedVideo } = ExecuteContext();

  const imageList = props?.imagelist;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="preview_screen"
    >
      <Modal.Body>
        <button className="close" type="button" onClick={props.onHide}>
          <IoMdClose />
        </button>
        {props?.type === "image" && (
          <Fragment>
            <button
              type="button"
              className="prev"
              disabled={slideNum === 0}
              onClick={() => setSlideNum((prev) => prev - 1)}
            >
              <MdOutlineArrowBackIosNew />
            </button>

            <img
              src={`${baseUrl}/${
                imageData?.filter((ele) => ele.type === "image")?.[slideNum]
                  ?.file
              }`}
              alt="Slide Preview"
            />
            <button
              type="button"
              className="next"
              disabled={
                slideNum ===
                imageData?.filter((ele) => ele?.type === "image")?.length - 1
              }
              onClick={() => setSlideNum((prev) => prev + 1)}
            >
              <MdOutlineArrowForwardIos />
            </button>
          </Fragment>
        )}

        {props?.type === "video" && (
          <div className="video_wrap">
            <iframe
              src={selectedVideo?.file}
              title="YouTube video player"
              allowFullScreen
            ></iframe>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ImagePreview;
