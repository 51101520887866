import { Loader, PageLanding, PageLayout } from "../../components/index.js";
import { images } from "../../utils/images.js";
import StremSec from "./stremSec/StreamSec.jsx";
import axios from "axios";
import { baseUrl, getLiveStreamPage } from "../../utils/apiData.js";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const LiveStream = () => {
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [landingImg, setLandingImg] = useState("");


  const liveStreamPageApi = async () => {
    
    setLoading(true);
    try {
      const res = await axios.get(`${baseUrl}/${getLiveStreamPage}`);
      if (res?.data?.success) {
        setLandingImg(res.data?.data?.landing_image);
        setDataList(res?.data?.data?.images);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    liveStreamPageApi();
  }, []);
  return (
    <PageLayout>
            <Helmet>
        <title>LiveStream | All India All Star</title>
        <meta name="description" content="LiveStream | All India All Star" />
      </Helmet>

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <PageLanding title="Live Stream" img={`${baseUrl}/${landingImg}`} />
          <StremSec dataList={dataList} />
        </Fragment>
      )}
    </PageLayout>
  );
};

export default LiveStream;
