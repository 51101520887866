import "./HomeLanding.scss";
import { motion } from "framer-motion";
import WistiaVideo from "../../../components/wistiaVideo/WistiaVideo";
import { useEffect, useState } from "react";
const HomeLanding = ({ data }) => {
  const title = `${data?.main_heading}`;

  const [view, setView] = useState(true);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setView(false);
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  return (
    <section className="home_landing">
      {data?.main_video_link && <WistiaVideo vId={data?.main_video_link} />}
    
      {view && (
        <h1 className="big">
          {title
            .split("")
            .map((char, k) => (
              <motion.span
                initial={{ translateX: 24, opacity: 0 }}
                whileInView={{ translateX: 0, opacity: 1 }}
                transition={{
                  duration: 0.6,
                  ease: "easeInOut",
                  delay: 0.1 * k,
                }}
                viewport={{ once: true }}
                key={k}
              >
                {char}
              </motion.span>
            ))
            .concat("")}
        </h1>
      )}
    </section>
  );
};

export default HomeLanding;
