import "./Loader.scss";
import { images } from "../../utils/images";
const Loader = () => {
  return (
    <section className="loader">
      <div className="content_wrap p_t p_b">
        <img src={images.logo} alt="Star" />
      </div>
    </section>
  );
};

export default Loader;
