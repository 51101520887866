import { Fragment } from "react";
import "./MenuDrower.scss";
import ExecuteContext from "../../utils/executeContext";
import Offcanvas from "react-bootstrap/Offcanvas";
import { images } from "../../utils/images";
import { IoMdClose } from "react-icons/io";
import { paths } from "../../utils/paths";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

const MenuDrower = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { menuStatus, setMenuStatus } = ExecuteContext();
  return (
    <Offcanvas
      show={menuStatus}
      onHide={() => setMenuStatus(false)}
      placement="top"
      className="menu_drower"
    >
      <Offcanvas.Header>
        <img src={images.logo} alt="Brand Logo" />
        <button
          type="button"
          className="close_btn"
          onClick={() => setMenuStatus(false)}
        >
          <IoMdClose />
        </button>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <nav>
          <NavLink
            to={paths.HOME}
            className={
              location?.pathname === "/" && location?.hash === ""
                ? "detect"
                : ""
            }
            onClick={() => setMenuStatus(false)}
          >
            Home
          </NavLink>
          <NavLink
            to={paths.ABOUT}
            className={
              location?.pathname === "/about_rsc" && location?.hash === ""
                ? "detect"
                : ""
            }
            onClick={() => setMenuStatus(false)}
          >
            About RSC
          </NavLink>
          <NavLink
            to={paths.ABOUTFOUNDER}
            className={
              location?.pathname === "/" && location?.hash === "#about_founder"
                ? "detect"
                : ""
            }
            onClick={() => setMenuStatus(false)}
          >
            About Navakodi
          </NavLink>
          <NavLink
            to={paths.ABOUTTEAM}
            className={
              location?.pathname === "/about_rsc" &&
              location?.hash === "#nvakodi's_legacy"
                ? "detect"
                : ""
            }
            onClick={() => setMenuStatus(false)}
          >
            Navakodi's Legacy
          </NavLink>
          <NavLink
            to={paths.ABOUTGRATITUDE}
            className={
              location?.pathname === "/about_rsc" &&
              location?.hash === "#gratitude"
                ? "detect"
                : ""
            }
            onClick={() => setMenuStatus(false)}
          >
            Gratitude
          </NavLink>
          <NavLink
            to={paths.GALLERY}
            className={location?.pathname === "/gallery" ? "detect" : ""}
            onClick={() => setMenuStatus(false)}
          >
            Gallery
          </NavLink>
          <NavLink
            to={paths.LIVESTREAM}
            className={location?.pathname === "/livestream" ? "detect" : ""}
            onClick={() => setMenuStatus(false)}
          >
            Live Stream
          </NavLink>
          <NavLink
            to={paths.EVENTS}
            className={location?.pathname === "/events" ? "detect" : ""}
            onClick={() => setMenuStatus(false)}
          >
            Events
          </NavLink>
          <NavLink
            to={paths.SPONSORS}
            className={location?.pathname === "/sponsors" ? "detect" : ""}
            onClick={() => setMenuStatus(false)}
          >
            Sponsors
          </NavLink>
          <button
            className="main_btn contact_btn"
            onClick={() => {
              navigate(paths.CONTACT);
              setMenuStatus(false);
            }}
          >
            Get in Touch
          </button>
        </nav>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MenuDrower;
