import { PageLanding, PageLayout } from "../../components/index.js";
import { images } from "../../utils/images.js";
import FormSec from "./formSec/FormSec.jsx";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <PageLayout>
            <Helmet>
        <title>Contact Us | All India All Star</title>
        <meta name="description" content="Contact Us | All India All Star" />
      </Helmet>
      <PageLanding title="Contact Us" img={images.contactLanding} />
      <FormSec />
    </PageLayout>
  );
};

export default Contact;
