import "./JoinCommunity.scss";
import { images } from "../../../utils/images";
import { Fragment } from "react";
import { LuArrowRight } from "react-icons/lu";
import { useRef, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const JoinCommunity = ({ data }) => {
  const textRef = useRef();
  const [move, setMove] = useState(0);
  const handleScroll = () => {
    if (textRef.current) {
      const rect = textRef.current.getBoundingClientRect();
      const scrollTop = window.scrollY;
      const distance = rect.top + scrollTop;

      setMove(
        (scrollTop - distance + window.innerHeight - window.innerHeight / 3) / 3
      );
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const title = data?.section_1_description;

  return (
    <section className="join_community p_t p_b">
      <div className="content_wrap">
        <h2 ref={textRef}>
          {move > 0 ? (
            <Fragment>
              <span className="white">
                {title
                  ?.split("")
                  ?.slice(0, move)
                  ?.map((t, e) => (
                    <Fragment key={e}>{t}</Fragment>
                  ))}
              </span>
              <span className="light">
                {title
                  ?.split("")
                  ?.slice(move)
                  ?.map((t, e) => (
                    <Fragment key={e}>{t}</Fragment>
                  ))}
              </span>
            </Fragment>
          ) : (
            <span className="light">{title}</span>
          )}
        </h2>
        <div className="box">
          <h3>{data?.cta_title}</h3>
          <div className="btn_side">
            {/* <a href={data?.cta_link} target="_blank">
              <button className="main_btn">
                {data?.cta_button_name}
                <LuArrowRight />
              </button>
            </a> */}
            <NavLink to="/contact" target="_blank">
              <button className="main_btn">
                {data?.cta_button_name}
                <LuArrowRight />
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinCommunity;
