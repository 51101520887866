import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import WebContext from "./components/WebContext";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WebContext>
     
        <App />
     
    </WebContext>
  </React.StrictMode>
);
reportWebVitals();
