import { Fragment, useEffect, useState } from "react";
import { Loader, PageLanding, PageLayout } from "../../components/index.js";
import SponserDetail from "../../components/sponserDetail/SponserDetail";

import DonationInfo from "./donationInfo/DonationInfo.jsx";
import axios from "axios";
import { baseUrl, getSponsorPage } from "../../utils/apiData.js";
import { Helmet } from "react-helmet";
import SportList from "./sponserList/SportList.jsx";
const Sponsors = () => {
  const [sponser, setSponser] = useState({});
  const [loading, setLoading] = useState(false);
  const [landingImg, setLandingImg] = useState("");
  const [sponserList, setSponserList] = useState([]);

  const sponserPageApi = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${baseUrl}/${getSponsorPage}`);
      if (res?.data?.success) {
        setSponser(res?.data?.data?.sponser);
        setLandingImg(res?.data?.data?.landing_image);
        setSponserList(res?.data?.data?.sponsar);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    sponserPageApi();
  }, []);

  return (
    <PageLayout>
      <Helmet>
        <title>Sponsors | All India All Star</title>
        <meta name="description" content="Sponsors | All India All Star" />
      </Helmet>

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <PageLanding
            title="Sponsors & Donation"
            img={`${baseUrl}/${landingImg}`}
          />
          <DonationInfo data={sponser} />
          <SportList dataList={sponserList} />
        </Fragment>
      )}
      <SponserDetail />
    </PageLayout>
  );
};

export default Sponsors;
