import "./ErrorPage.scss";
import { useNavigate } from "react-router-dom";
import { images } from "../../utils/images";
import { paths } from "../../utils/paths";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <section className="error_page">
      <div className="content_wrap p_t p_b">
        <img src={images.error} alt="" />
        <h2>Page Not Found</h2>
        <p className="dark">
          The page you are looking for doesn't exist or has been moved
        </p>
        <button className="main_btn" onClick={() => navigate(paths.HOME)}>
          Go To Home
        </button>
      </div>
    </section>
  );
};

export default ErrorPage;
