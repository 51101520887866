import "./WhyWeCare.scss";
import { baseUrl } from "../../../utils/apiData";

const WhyWeCare = ({ data }) => {
  return (
    <section className="why_we_care p_t">
      <div className="content_wrap">
        <h1 className="sec_title">{data?.sport_title}</h1>
        <div className="bi_grid">
          <div className="left">
          
            <h4 dangerouslySetInnerHTML={{ __html: data?.sport_description?.replace(/\n/g, "<br />") }} />
          </div>
          <div className="img_wrap">
         
            <img src={`${baseUrl}/${data?.sport_image}`} alt="Why We Care" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyWeCare;
