import "./SponserDetail.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import ExecuteContext from "../../utils/executeContext";
import { IoClose } from "react-icons/io5";
import { baseUrl } from "../../utils/apiData";

const SponserDetail = () => {
  const { sponserStatus, setSponserStatus, sponser } = ExecuteContext();

  return (
    <Offcanvas
      placement="end"
      show={sponserStatus}
      onHide={() => setSponserStatus(false)}
      className="event_detail"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>
          <h5>{sponser?.name}</h5>
          <button
            type="button"
            className="close"
            onClick={() => setSponserStatus(false)}
          >
            <IoClose />
          </button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="img_wraper">
          <img src={`${baseUrl}/${sponser?.image}`} alt="Sponser" />
        </div>
        <p className="dark">{sponser?.description}</p>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SponserDetail;
