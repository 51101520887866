import WistiaVideo from "../../../components/wistiaVideo/WistiaVideo";
import { baseUrl } from "../../../utils/apiData";
import { images } from "../../../utils/images";
import "./WhoWeAre.scss";

const WhoWeAre = ({ data }) => {
  return (
    <section className="who_we_are p_t p_b">
      <div className="content_wrap">
        <h1>{data?.section_1_heading}</h1>
        <div className="bi_sec">
          <div className="img_wrap">
              <img src={`${baseUrl}/${data?.section_1_image}`} alt="Sport Bird" />              
          </div>
          <div className="text_wrap">
            <h4>{data?.section_1_title}</h4>
            <p className="light" dangerouslySetInnerHTML={{ __html: data?.section_1_description?.replace(/\n/g, "<br />") }} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
