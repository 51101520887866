import "./Footer.scss";
import { paths } from "../../utils/paths";
import { NavLink, useLocation } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import ExecuteContext from "../../utils/executeContext";
import { baseUrl } from "../../utils/apiData";

const Footer = () => {
  const { footerData } = ExecuteContext();
  const location = useLocation();
  return (
    <footer>
      <section className="footer_sec">
        <div className="content_wrap">
          <div className="bi_sec">
            <div className="img_wrap">
              <img
                src={`${baseUrl}/${footerData?.image}`}
                alt="Enjoing Peoples"
                className="bg"
              />{" "}
              <div className="layer_wrap">
                <h3>{footerData?.title}</h3>
              </div>
            </div>
            <div className="info_side">
              <div className="link_grid">
                <div className="link_col">
                  <NavLink
                    to={paths.HOME}
                    className={
                      location?.pathname === "/" && location?.hash === ""
                        ? "detect"
                        : ""
                    }
                  >
                    Home
                  </NavLink>
                  <NavLink
                    to={paths.ABOUT}
                    className={
                      location?.pathname === "/about_rsc" &&
                      location?.hash === ""
                        ? "detect"
                        : ""
                    }
                  >
                    About RSC
                  </NavLink>
                  <NavLink
                    to={paths.ABOUTFOUNDER}
                    className={
                      location?.pathname === "/" &&
                      location?.hash === "#about_founder"
                        ? "detect"
                        : ""
                    }
                  >
                    About Navakodi
                  </NavLink>
                  <NavLink
                    to={paths.ABOUTTEAM}
                    className={
                      location?.pathname === "/about_rsc" &&
                      location?.hash === "#nvakodi's_legacy"
                        ? "detect"
                        : ""
                    }
                  >
                    Navakodi's Legacy
                  </NavLink>
                </div>
                <div className="link_col">
                <NavLink
                  to={paths.GALLERY}
                  className={location?.pathname === "/gallery" ? "detect" : ""}
                >
                  Gallery
                </NavLink>
                <NavLink
                  to={paths.LIVESTREAM}
                  className={
                    location?.pathname === "/livestream" ? "detect" : ""
                  }
                >
                  Live Stream
                </NavLink>
                <NavLink
                  to={paths.EVENTS}
                  className={location?.pathname === "/events" ? "detect" : ""}
                >
                  Events
                </NavLink>

                  <NavLink to={paths.ABOUTGRATITUDE}
                   className={
                    location?.pathname === "/about_rsc" && location?.hash === "#gratitude"
                      ? "detect"
                      : ""
                  }
                  
                  >Gratitude</NavLink>
                </div>
                <div className="link_col">
                <NavLink
                  to={paths.SPONSORS}
                  className={location?.pathname === "/sponsors" ? "detect" : ""}
                >
                  Sponsors
                </NavLink>
                <NavLink
                  to={paths.CONTACT}
                  className={location?.pathname === "/contact" ? "detect" : ""}
                >
                  Contact
                </NavLink>
                </div>
              </div>
              <div className="director_card">
                <a href={`mailto:${footerData?.email}`} target="_blank">
                  <h5>{footerData?.email}</h5>
                </a>
                <p className="dark">Contact Us</p>
              </div>
              <div className="social_line">
                {footerData?.facebook_link && (
                  <a href={footerData?.facebook_link} target="_blank">
                    <FaFacebookF />
                  </a>
                )}

                {footerData?.instagram_link && (
                  <a href={footerData?.instagram_link} target="_blank">
                    <FaInstagram />
                  </a>
                )}

                {footerData?.linkedin_link && (
                  <a href={footerData?.linkedin_link} target="_blank">
                    <FaLinkedinIn />
                  </a>
                )}
                {footerData?.twitter_link && (
                  <a href={footerData?.twitter_link} target="_blank">
                    <FaXTwitter />
                  </a>
                )}
              </div>
              <p className="light rights">
                © Copyright {new Date().getFullYear()} All India All Star.
                Designed & Developed by{" "}
                <a href="https://supagrow.in/" target="_blank">
                  Supagrow
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
