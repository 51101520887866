import "./StreamSec.scss";
import { LuArrowRight } from "react-icons/lu";

const StremSec = ({ dataList }) => {
  return (
    <section className="stream_sec p_t p_b">
      <div className="content_wrap">
        <h1 className="sec_title">View live Stream</h1>
        {dataList?.length > 0 ? (
          dataList?.map((data) => {
            return (
              <div className="bi_sec" key={data?._id}>
                <div className="video_wrap">
                  <iframe
                    src={data?.live_link}
                    title="YouTube video player"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="text_wrap">
                  <h2>{data?.title}</h2>
                  <p className="dark">
                 {data?.description}
                  </p>
                  <a href={data?.live_link} target="_blank">
                    <button className="main_btn">
                      Watch Now <LuArrowRight />
                    </button>
                  </a>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center alert_text">There are no live streams available at the moment...</p>
        )}
      </div>
    </section>
  );
};

export default StremSec;
