import "./WhyPleadge.scss";
import { baseUrl } from "../../../utils/apiData";

const WhyPleadge = ({ data }) => {
  return (
    <section className="why_pleadge p_t p_b">
      <div className="img_wrap">
        <img src={`${baseUrl}/${data?.about_image}`} alt="Persons image" />
        <div className="overlay_wrap"></div>
        <div className="text_layer">
          <h2>{data?.about_heading}</h2>
          <p
            className="dark"
            dangerouslySetInnerHTML={{
              __html: data?.about_description?.replace(/\n/g, "<br />"),
            }}
          />
        </div>
      </div>
      <div className="text_wrap">
        <h1>{data?.about_title}</h1>
        {data?.inspire_1_title && data?.inspire_1_description && (
          <div className="text_card">
            <h4>{data?.inspire_1_title}</h4>
            <p className="dark">{data?.inspire_1_description}</p>
          </div>
        )}

        {data?.inspire_2_title && data?.inspire_2_description && (
          <div className="text_card">
            <h4>{data?.inspire_2_title}</h4>
            <p className="dark">{data?.inspire_2_description}</p>
          </div>
        )}

        {data?.inspire_3_title && data?.inspire_3_description && (
          <div className="text_card">
            <h4>{data?.inspire_3_title}</h4>
            <p className="dark">{data?.inspire_3_description}</p>
          </div>
        )}

        {data?.inspire_4_title && data?.inspire_4_description && (
          <div className="text_card">
            <h4>{data?.inspire_4_title}</h4>
            <p className="dark">{data?.inspire_4_description}</p>
          </div>
        )}

        {data?.inspire_5_title && data?.inspire_5_description && (
          <div className="text_card">
            <h4>{data?.inspire_5_title}</h4>
            <p className="dark">{data?.inspire_5_description}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default WhyPleadge;
