export const paths = {
  HOME: "/",
  ABOUT: "/about_rsc",
  ABOUTFOUNDER: "/#about_founder",
  ABOUTTEAM: "/about_rsc#nvakodi's_legacy",
  ABOUTGRATITUDE:"/about_rsc#gratitude",
  GALLERY: "/gallery",
  CONTACT: "/contact",
  EVENTS: "/events",
  LIVESTREAM: "/livestream",
  SPONSORS: "/sponsors",
  ERROR: "*",
};
