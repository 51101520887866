import "./EventDetail.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import ExecuteContext from "../../utils/executeContext";
import { IoClose } from "react-icons/io5";
import { baseUrl } from "../../utils/apiData";

const EventDetail = () => {
  const { eventdetailStatus, setEventDetailStatus, event } = ExecuteContext();
  const formatDate = (dateStr) => {
    const date = new Date(dateStr); // Convert the string to a Date object
    const formattedDate = date.toLocaleString("default", {
      month: "short", // Abbreviated month name (e.g., Jun)
      year: "numeric", // Full year (e.g., 2025)
    });
    return formattedDate;
  };
  return (
    <Offcanvas
      placement="end"
      show={eventdetailStatus}
      onHide={() => setEventDetailStatus(false)}
      className="event_detail"
    >
      <Offcanvas.Header>
        <Offcanvas.Title>
          <h5>
            {event?.name}
            <br />
            <p className="dark date mt-1">{formatDate(event?.publish_date)} </p>
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => setEventDetailStatus(false)}
          >
            <IoClose />
          </button>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p className="dark category">{event.category}</p>
        <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: event?.description }}
        ></div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EventDetail;
